import React from 'react';
import { graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { BlogIndex } from '../components/blog-index';

const BlogPostIndex = props => {
  const { data } = props;
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMdx.edges;

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Smelly Code | Personal blog by Hitesh" />
      <Bio />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <BlogIndex
            key={node.fields.slug}
            slug={node.fields.slug}
            title={title}
            date={node.frontmatter.date}
            readingTime={node.fields.readingTime}
            description={node.frontmatter.description || node.excerpt}
            tags={node.frontmatter.tags}
          />
        );
      })}
    </Layout>
  );
};

export default BlogPostIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }

    coverImages: allFile(
      filter: {
        sourceInstanceName: { eq: "blog" }
        name: { regex: "/cover-img/" }
      }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`;
